import styles from './InstructionFilter.module.css';

import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from '../../ui/ProgressBar';
import Control from '../../containers/Control';
import checkCondition from '../../../helpers/checkCondition';
import withInstructionFilter from './hoc/withInstructionFilter';
import updateQueryStringWithParams from '../../../helpers/updateQueryStringWithParams';
import { getLink } from '../../../bootstrap/services/widgetService';
import Capitalize from '../../../helpers/capitalize';
import AnalyticsLoader from '../../containers/AnalyticsLoader';
import Preview from '../../containers/Preview';
import FinalScreen from '../../containers/FinalScreen';
import scrollToItem from "../../../helpers/scrollToItem";

const InstructionFilter = ({ handleChange, filter, instruction, quizType, theme, noPreview, widgetClass }) => {
  const [isPreviewShown, handleShownPreview] = useState(true);
  const [isFilterShown, handleShownFilter] = useState(false);
  const [isFilterFilled, handleFilledFilter] = useState(false);
  const [answered, handleAnswered] = useState([]);
  const [amount, handleAmount] = useState(0);
  const itemsSection = useRef();
  const { values, cta, theses, intro, category, slug, title } = instruction;
  const targetRef = useRef();
  let index = 0;

  if (isFilterShown && itemsSection.current) {
    itemsSection.current.style.overflow = 'hidden';

    setTimeout(() => {
      itemsSection.current.style.overflow = '';
    }, 1000);
  }

  const handleAmountValue = () => {
    let _amount = 0;
    const _answered = answered;

    values.map((value) => {
      if (checkCondition(value.condition, filter)) _amount += 1;
      else if (_answered.includes(value.id)) _answered.splice(_answered.indexOf(value.id), 1);
    });

    handleAnswered(_answered);

    if (answered.length > 0 && _amount === answered.length) {
      handleShownFilter(false);
      itemsSection.current.style.overflow = 'hidden';
    } else {
      handleShownFilter(true);
      handleFilledFilter(false);

      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth', // Добавляет плавную анимацию
      //   block: 'start', // Прокручивается так, чтобы верхняя часть элемента была видна
      // });

      // const scrollToResult = (selector) => {
      //   const item = document.querySelector(selector);
      //   scrollToItem({ item, minus: 20 });
      // };
      // scrollToResult(".destra-quiz");
    }
    handleAmount(_amount);
  };

  const handleTheses = () => {
    const _theses = theses.find(item => checkCondition(item.condition, filter));

    if (_theses) return _theses;
    else return { label: title };
  }
  const fromLabel = useRef('');

  useEffect(() => {
    if (noPreview) handleShownPreview(false);
    if (answered.length > 0 && amount === answered.length) handleShownFilter(false);
    handleAmountValue();
    if (window.location.pathname.includes('blog')) {
      fromLabel.current = '&from=blog_widget_quiz';
    }
  }, [filter]);

  const handleFilterChange = (id, newValue, type) => {
    const _answered = answered;

    if (_answered.includes(id)) _answered.splice(_answered.indexOf(id));
    if (type !== 'multiselect') _answered.push(id);

    handleAnswered(_answered);

    if (type === 'toggleasradio') {
      if (newValue === 1) newValue = false;
      else newValue = true;
      type = 'toggle';
      handleChange(id, newValue, type, _answered);
    } else handleChange(id, newValue, type, _answered);

    handleAmountValue();
  };

  const onMultiselectButtonClick = (id) => {
    const _answered = answered;
    if (!_answered.includes(id)) _answered.push(id);
    handleAnswered(_answered);
    handleAmountValue();
  };

  const onContinueButtonClick = () => {
    updateQueryStringWithParams({ filter }, (res) => {
      if (window.destraParams) {
        const { partnerId, offerId } = window.destraParams;

        const paramsString = res.join('&');
        const addParams = `&utm_source=partner_widget&utm_medium=${partnerId}&utm_campaign=${offerId}`;
        const redirectLink = quizType === 'order-quiz' ? `https://destralegal.ru/lawyer-order/${category.slug}/${slug}?${paramsString}${fromLabel.current}${addParams}` : `https://destralegal.ru/catalog/${category.slug}/${slug}?${paramsString}${fromLabel.current}${addParams}`;
        window.location.href = redirectLink;

      } else {
        const paramsString = res.join('&');
        const redirectLink = quizType === 'order-quiz' ? `https://destralegal.ru/lawyer-order/${category.slug}/${slug}?${paramsString}${fromLabel.current}` : `https://destralegal.ru/catalog/${category.slug}/${slug}?${paramsString}${fromLabel.current}`;
        window.location.href = redirectLink;
      }
    });
  }

  const renderFilterStatus = () => {
    if (isPreviewShown) return 'preview';
    if (isFilterShown) return 'process';
    if (isFilterFilled) return 'filled';
    

    const scrollToResult = (selector) => {
      const item = document.querySelector(selector);
      scrollToItem({ item, minus: 20 });
    };
    const uniqueWidgetClass = widgetClass.split(' ')[1]
    // console.log("-----uniqueWidgetClass--------", uniqueWidgetClass)
    // scrollToResult(".destra-quiz");
    if (uniqueWidgetClass) scrollToResult(`.${uniqueWidgetClass}`);
    if (!uniqueWidgetClass) scrollToResult(".destra-quiz");
    return 'analysis';
  }

  const addUtmInLocalstorage = () => {
    window.localStorage.setItem("utmForm", "blog_widget_quiz");
    const storageEvent = new StorageEvent('storage', {
      key: "utmForm",
      newValue: "blog_widget_quiz",
    });
    window.dispatchEvent(storageEvent);
  };

  return (
    <div ref={targetRef} className={`${styles.component} ${styles[renderFilterStatus()]} ${styles[theme]}`}>
      {(isPreviewShown && !noPreview) && (
        <Preview title={title} onClick={() => handleShownPreview(false)} />
      )}
      {!isFilterFilled ? (
        <div className={`${styles.section} ${styles.sectionTitle}`}>
          <div className={styles.title}>{cta}</div>
        </div>
      ) : null}
      <div className={`${styles.section} ${styles.sectionItems}`} ref={itemsSection}>
        <div className={styles.items}>
          {values && values.map((value) => {
            const className = `${styles.itemsSection} ${styles[`itemsSection${Capitalize(value._type)}`]}`

            if (value._type === 'toggle') {
              value._type = 'toggleasradio';
              value.value = [{ id: 0, value: 'Да' }, { id: 1, value: 'Нет' }];
            }

            if (value._type === 'multiselect') {
              value.withButton = true;
              value.onButtonClick = onMultiselectButtonClick;
            }

            if (checkCondition(value.condition, filter)) {
              index += 1;
              const params = {
                checkCondition, // TODO: move from props to @helpers
                value,
                handleChange: (id, newValue, type) => handleFilterChange(id, newValue, type),
                filter
              };

              if (index <= answered.length + 1) {
                return (
                  <div key={value._id} className={className}>
                    <Control {...params} />
                  </div>
                );
              }
            }
          })}
        </div>
      </div>
      {isFilterShown ? (
        <div className={`${styles.section} ${styles.sectionProgress}`}>
          <div className={styles.progress}>
            <div className={`${styles.progressSection} ${styles.progressSectionStage}`}>
              <div className={styles.stage}>
                {`${answered.length} / ${amount}`}
              </div>
            </div>
            <div className={`${styles.progressSection} ${styles.progressSectionLine}`}>
              <ProgressBar withNumber={false} progress={answered.length} quantity={amount} />
            </div>
            <div className={`${styles.progressSection} ${styles.progressSectionPercent}`}>
              <div className={styles.percent}>
                {`${Math.floor(answered.length / amount * 100)}%`}
              </div>
            </div>
          </div>
        </div>
      ) : null}


      {!isFilterShown && !isFilterFilled ? (
        <div className={`${styles.section} ${styles.sectionLoader}`}>
          <AnalyticsLoader onProgressEnd={() => {
            handleFilledFilter(true);
          }} quantity='100' />
        </div>
      ) : null}
      {isFilterFilled ? <FinalScreen theses={handleTheses()} intro={intro} filter={filter} onClick={noPreview ? addUtmInLocalstorage : onContinueButtonClick} /> : null}
    </div>
  );
};
export default withInstructionFilter(InstructionFilter);
